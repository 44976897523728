import React, { FC } from 'react';

interface RowNavigationHandlerProps {
  children?: (JSX.Element | false | null | undefined)[];
  onClick?: () => void;
}
/**
 * Wrapper component for providing Table Row onClick to one component,
 * which can then propagate the click handler downward to its child
 * components. Removes the need for manually binding onClick to every
 * cell component individually.
 */
export const RowNavigationHandler: FC<RowNavigationHandlerProps> = ({ children, ...props }) => {
  const validChildren = children?.filter(child => !!child) as JSX.Element[];
  return (
    <>
      {React.Children.map(validChildren, child => React.cloneElement(child, { ...props }))}
    </>
  );
};
