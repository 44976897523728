import {
  TableRow, TableRowProps, TableCell
} from '@instech/components';
import styled, { css } from 'styled-components';
import { tableCellVariant } from '../types';

/**
 * TableRow component for displaying the splits of an invoice row as
 * expandable rows underneath its corresponding invoice row. Follows
 * a similar structure as <TableRowWithColor />
 */
export const ExpandableTableRow = styled(TableRow) <TableRowProps>`
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.marineBlue} !important;
  ${({ theme, even }) => css`
    & ${TableCell} {
      background-color: ${even ? theme.marineBlue20 : theme.marineBlue10};
      &[data-cell-variant="${tableCellVariant.secondary}"],
      &[data-cell-variant="${tableCellVariant.tertiary}"] {
        background-color: ${even ? theme.marineBlue30 : theme.marineBlue20};
      }
    }
  `}
`;
