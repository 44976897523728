/**
 * Returns the truthy value of a provided string or number, as a text string,
 * or returns a small dash if not truthy. Starting off simple and can extend
 * function later to cover more particular cases.
 */
export const textOrDash = (string?: string | number | null): string => {
  if (!string || string === '') {
    return '-';
  }
  return String(string);
};

export const textOrAsterisk = (string?: string | number | null): string => (!string || string === '') ? '*' : String(string);

export const ellipsisOrDash = (string: string | undefined | null, beforeEllipsis: number = 25): string => {
  const text = textOrDash(string);
  return text.length > 25 ? `${text.substring(0, beforeEllipsis)}...` : text;
};

export const textOrNone = (string: string | undefined | null): string => {
  if (!string) {
    return '';
  }
  return string.length > 25 ? string : '';
};

export const prependDashOrEmpty = (value?: any) => value ? `- ${value}` : '';

export const lowercaseFirstLetter = (string: string) => (
  string.charAt(0).toLowerCase() + string.slice(1)
);

/**
 * Trims whitespace before, after, and in the middle of a given string.
 * Extension of string.trim(), which only trims before and after.
 */
export const textTrimAll = (string: string) => (
  string.replace(/\s{2,}/g, ' ').trim()
);

/**
 * Takes number 0 - 25 and returns corresponding character code
 * A - Z. Input outside of this valid range return nothing.
 */
export const indexToLetter = (index: number) => {
  if (Number.isNaN(index) || index < 0 || index > 25) {
    // eslint-disable-next-line no-console
    console.error('Function expected number between 0 and 25 (received "%d")', index);
    return '';
  }
  return String.fromCharCode(index + ('A'.charCodeAt(0)));
};
